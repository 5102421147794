<template>
  <div>
    <section class="listings">
      <ProfileRingTabs class="py-3" :tabs="tabs" :pressed="currentTab" @tab-changed="tabChanged" />

      <div class="py-3">
        <SearchInput
          v-model="text"
          :search-icon="true"
          :shortcut-icon="false"
          :clear-icon="false"
          :hide-shortcut-icon-on-blur="true"
          :clear-on-esc="false"
          :blur-on-esc="true"
          :select-on-focus="false"
          :shortcut-listener-enabled="true"
          placeholder="Aa"
          @input="onSearch"
        />
      </div>
      <ChLoading size="lg" class="mx-auto" v-if="loading" />
      <div v-else>
        <div v-if="currentTab === 'featured'">
          <ProfileRingsSection
            :items="ringCategories"
            :white-card-color="true"
            :amountSection="true"
            @updated="fetchUpdatedPrfRingCategories"
            @fetch="fetchRingCategories"
          />
        </div>
        <div v-else>
          <div v-for="(category, key) in subCategories" :key="key">
            <div v-if="!isEmpty(category)">
              <div class="title">{{ key }}</div>
              <ProfileRingsSection
                :items="category"
                :white-card-color="true"
                :amountSection="true"
                @updated="fetchUpdatedPrfRingCategories"
                @fetch="fetchRingCategories"
              />
            </div>
          </div>
          <div v-if="isEmpty(ringCategories) && !loading" class="no-data">No Rings Yet!</div>
        </div>
        <ChLoading size="md" v-if="pageLoad" class="mx-auto" />
        <p v-else-if="nextPageExists && !pageLoad" class="clickable text-center" @click="requestLoadMore">Load More</p>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import SearchInput from 'vue-search-input';
import ProfileRingsSection from '@/shared/pages/profile/components/VirtualItemsSection.vue';
import ProfileRingTabs from '@/shared/pages/store/ProfileRingTabs.vue';
import { Paging, Tab } from '@/shared/types/static-types';
import { profileRingsTabs } from '@/shared/statics/tabs';
import { getShopVirtualItems } from '@/shared/actions/virtualItems';
const props = defineProps({
  profileRingCategories: { type: Array, default: [] },
});

const emits = defineEmits(['updated', 'search']);
const currentTab = ref('featured');
const tabIndex = ref(0);
const currentPage = ref(1);
const profileRingCategories: any = toRef(props, 'profileRingCategories');
const text = ref('');
const router = useRouter();
const loading = ref(true);
const pageLoad = ref(false);
const tabs = ref(profileRingsTabs);
const route = useRoute();
const ringCategories: any = ref({});
const ringPaging: any = ref<Paging>();
const getTabIndex = (text: any) => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === text)
  );

  if (tIndex >= 0) {
    tabIndex.value = tIndex;
    currentTab.value = tabs.value[tabIndex.value].value;
  }
};
const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  getTabIndex(currentTab.value);
  router.replace({ name: 'shop', query: { tab: 'profile-rings-shop', title: currentTab.value } });
};
watch(
  profileRingCategories,
  () => {
    const otherCategories = profileRingCategories.value.map((item: any) => {
      const value = item.title.toLowerCase().replace(/ /g, '_');
      return { name: item.title, value: value };
    });
    tabs.value = tabs.value.concat(otherCategories);

    getTabIndex(route.query.title);
  },
  { immediate: true }
);
const fetchRingCategories = async (page = 1) => {
  pageLoad.value = true;
  const searchCriteria: any = !isEmpty(text.value)
    ? { search: text.value, page, page_size: 40 }
    : { page, page_size: 40 };

  currentTab.value === 'all'
    ? ''
    : currentTab.value === 'featured'
    ? (searchCriteria.is_featured = true)
    : (searchCriteria.main_category__title = tabs.value[tabIndex.value].name);

  const { results, ...paging } = await getShopVirtualItems(searchCriteria);

  ringCategories.value = page === 1 ? results : ringCategories.value.concat(results);

  if (ringCategories.value) {
    ringCategories.value = ringCategories.value.filter(
      (val: any) =>
        isNull(get(val, 'in_store_till')) || new Date().getTime() <= new Date(get(val, 'in_store_till')).getTime()
    );
  }
  ringPaging.value = paging;
  currentPage.value = page;
  pageLoad.value = false;
  loading.value = false;
};

const subCategories = computed(() => {
  const groupedCategories = ringCategories.value.reduce((acc: any, item: any) => {
    const subCategoryTitle = get(item.sub_category, 'title') || 'Others';
    acc[subCategoryTitle] = (acc[subCategoryTitle] || []).concat(item);
    return acc;
  }, {});

  return groupedCategories;
});

const nextPageExists = computed(() => {
  return !!get(ringPaging.value, 'next');
});
const requestLoadMore = async (ev: CustomEvent) => {
  if (!ringPaging.value.next) {
    (ev?.target as any).complete();
  } else {
    await fetchRingCategories(currentPage.value + 1);
  }
};

watch(currentTab, async () => {
  if (currentTab.value) {
    ringCategories.value = [];
    text.value = '';

    fetchRingCategories();
  }
});

const fetchUpdatedPrfRingCategories = () => {
  emits('updated');
  fetchRingCategories();
};
const searchTextChanged = debounce(async () => {
  await fetchRingCategories();
}, 500);
const onSearch = () => {
  searchTextChanged();
};
onMounted(async () => {
  fetchRingCategories();
});
</script>

<style lang="sass" scoped>
.title
  font-size:20px
  color: #214163
  font-weight: bold
</style>
