<template>
  <section class="listings">
    <div v-if="specialOffers?.length">
      <div class="title mt-3">Special Offers</div>
      <ShopProductRowCard
        v-for="(listing, index) in specialOffers"
        :product="listing"
        @reload="emits('fetchGemListings')"
        class="mb-2"
      />
    </div>
  </section>
  <!-- <DailyClaimBox v-if="featureFlags.currency" /> -->
  <section class="listings">
    <StoreDescOffersSection :products="items" :dailyClaimBoxIndex="5" />
  </section>
  <section class="listings">
    <div class="title mt-3">Gem Packs</div>
    <StoreOffersSection
      :products="gemPacks"
      :white-card-color="true"
      :amountSection="true"
      :ringShopBtn="true"
      @updated="emits('updated')"
      @reload="emits('fetchGemListings')"
    />
  </section>

  <section class="listings" v-if="featureFlags.profileRing">
    <div class="title mt-3">Profile Rings</div>
    <ProfileRingsSection
      :items="rings"
      :white-card-color="true"
      :amountSection="true"
      :ringShopBtn="true"
      @updated="emits('updated')"
      @fetch="emits('fetchVirtualItems')"
    />
  </section>
</template>

<script lang="ts" setup>
import ProfileRingsSection from '@/shared/pages/profile/components/VirtualItemsSection.vue';
import { featureFlags } from '@/shared/config/feature-flags';
import ShopProductRowCard from '@/shared/components/Store/ShopProductRowCard.vue';
import StoreOffersSection from './StoreOffersSection.vue';
import StoreDescOffersSection from './StoreDescOffersSection.vue';
import { StoreProduct } from '@/shared/types/static-types';

const emits = defineEmits(['updated', 'fetchVirtualItems', 'fetchGemListings']);
const props = defineProps({
  items: { type: Array, default: [] as StoreProduct[] },
  specialOffers: { type: Array, default: [] as StoreProduct[] },
  rings: { type: Array, default: [] },
  gemPacks: { type: Array, default: [] as StoreProduct[] },
});
const items = toRef(props, 'items');
const rings = toRef(props, 'rings');
const gemPacks = toRef(props, 'gemPacks');
const specialOffers = toRef(props, 'specialOffers');
</script>

<style lang="sass" scoped>
.title
  font-size: 26px
  color: #431c89
  font-weight: bold

.listings
  max-width: 100%
  position: relative !important
</style>
